<template>
  <v-container>
    <Message
        :message="message"
        @closeAlert="message.show = false"
    ></Message>
    <p>
      Du hast aktuell
      <b v-if="allowedCustomFields <= getCustomFieldLength(customFields)" class="red--text">{{ getCustomFieldLength(customFields) }}</b>
      <b v-else>{{ getCustomFieldLength(customFields) }}</b>
      von
      <b>{{ allowedCustomFields }}</b>
      individuellen Feldern erstellt. <a href="">Mehr Felder nötig?</a>
    </p>
    <p>
      Du kannst individuelle Felder benutzen, um deine Datensätze mit Informationen zu erweitern, die du häufig benötigst.
    </p>
    <v-expansion-panels accordion class="">
      <v-expansion-panel active>
        <v-expansion-panel-header><h3>Kunde</h3></v-expansion-panel-header>
        <v-expansion-panel-content>
          <CustomFieldEntitySettings :user="user" :customFields="customFields.customer" :entity="'customer'"></CustomFieldEntitySettings>
        </v-expansion-panel-content>
      </v-expansion-panel>

      <v-expansion-panel active>
        <v-expansion-panel-header><h3>Dienstleister</h3></v-expansion-panel-header>
        <v-expansion-panel-content>
          <CustomFieldEntitySettings :user="user" :customFields="customFields.vendor" :entity="'vendor'"></CustomFieldEntitySettings>
        </v-expansion-panel-content>
      </v-expansion-panel>

      <v-expansion-panel active>
        <v-expansion-panel-header><h3>Hochzeit</h3></v-expansion-panel-header>
        <v-expansion-panel-content>
          <CustomFieldEntitySettings :user="user" :customFields="customFields.wedding" :entity="'wedding'"></CustomFieldEntitySettings>
        </v-expansion-panel-content>
      </v-expansion-panel>

      <v-expansion-panel active>
        <v-expansion-panel-header><h3>Event</h3></v-expansion-panel-header>
        <v-expansion-panel-content>
          <CustomFieldEntitySettings :user="user" :customFields="customFields.event" :entity="'event'"></CustomFieldEntitySettings>
        </v-expansion-panel-content>
      </v-expansion-panel>

      <v-expansion-panel active>
        <v-expansion-panel-header><h3>Aufgabe</h3></v-expansion-panel-header>
        <v-expansion-panel-content>
          <CustomFieldEntitySettings :user="user" :customFields="customFields.task" :entity="'task'"></CustomFieldEntitySettings>
        </v-expansion-panel-content>
      </v-expansion-panel>
    </v-expansion-panels>
  </v-container>
</template>
<script>

import {mapGetters} from "vuex";
import {error, msgObj, success} from "@/helper/helper";
const CustomFieldEntitySettings = () => import("@/components/entities/Settings/CustomFieldEntitySettings");
const Message = () => import("@/components/generalUI/Message");

export default {
  name: "FieldSettings",
  components:{
    CustomFieldEntitySettings,
    Message
  },
  computed: {
    ...mapGetters('settings', {
      customFields: 'customFields',
    }),
  },
  data() {
    return {
      message: msgObj(),
      allowedCustomFields: 3
    }
  },
  props: {
    user: {
      type: Object,
      default() {
        return {}
      }
    },
  },
  mounted() {
    this.$store.dispatch('settings/getCustomFields', {
      uid: this.user.id,
    }).catch((err) => {
      this.message = error(err)
    })
  },
  methods: {
    addVendorCategory(name) {
      this.$store.dispatch('settings/addVendorCategory', {
        uid: this.user.id,
        name,
        icon: this.icon
      }).then(() => {
        this.newCategory = ''
      }).catch((err) => {
        this.message = error(err)
      })
    },
    deleteStatus(statusCollection, id) {
      this.$store.dispatch('settings/deleteStatus', {
        uid: this.user.id,
        id,
        statusCollection,
      }).then(() => {
        this.message = success('Status erfolgreich gelöscht.')
      }).catch((err) => {
        this.message = error(err)
      })
    },
    getCustomFieldLength(customFields){
      let length = 0
      for(let  [key,value] of Object.entries(customFields)){
        console.log(key)
        length+=value.length
      }
      return length
    }
  }
}
</script>
